<script>
	import {
		Email,
		Reddit,
		LinkedIn,
		Telegram,
		Facebook,
		Twitter
	} from 'svelte-share-buttons-component';
	import { Version } from '$lib/store.js';
	export let url = 'https://' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'),
		title = 'Magicflow, the productivity tracker for deep work',
		desc =
			'Optimise your productivity with Magicflow, a productivity tracker & coach that helps you focus.';
</script>

<div class="flex gap-1 justify-between reinvert">
	<Facebook class="share-button" quote={title} {url} />
	<Twitter
		class="share-button"
		text={title}
		{url}
		via="magicflowHQ"
		related="magicflowHQ,michaeljelly"
	/>
	<Reddit class="share-button" {title} {url} />
	<LinkedIn class="share-button" {url} />
	<Telegram class="share-button" text={title} {url} />
	<Email subject={title} body="{desc} {url}" />
</div>

<style>
	:global(.ssbc-button--reddit) {
		background-color: #ff4500 !important;
		color: #fff;
	}
	:global(.ssbc-button--reddit:hover) {
		background-color: #ee3500 !important;
		color: #fff;
	}
</style>
