<script>
	import { copyTextToClipboard } from '$lib/utils.js';
	export let textToCopy = 'copy me';
	export let description = 'your personal referral link';

	let copyStatus;
</script>

<a
	class="p-4 pb-6 mb-4 text-xs text-white bg-gray-900 hover:bg-gray-800 {textToCopy
		? 'cursor-pointer'
		: ''} bg-opacity-50 border-gray-800 border rounded-2xl"
	on:click={async (event) => {
		event.preventDefault();
		if (!textToCopy) return;
		let reflink = document.querySelector('#referralLink');
		copyStatus = 'Copying...';
		await copyTextToClipboard(textToCopy);
		copyStatus = 'Copied!';
		setTimeout(() => (reflink.innerText = textToCopy), 3000);
	}}
	href="/#"
	role="button"
>
	<p class="text-sm mb-2">{copyStatus || `Click to copy ${description}!`}</p>
	<div
		id="referralLink"
		class="p-2 text-xs bg-gray-800 {textToCopy
			? 'cursor-pointer'
			: ''} bg-opacity-50 border-gray-700 border rounded-lg break-words"
	>
		{textToCopy}
	</div>
</a>
