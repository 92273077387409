<script>
	import { IsNative, Version, IsElectron, EthiClaims, Karma } from '$lib/store.js';
	import { fade } from 'svelte/transition';
	import Sharing from './Sharing.svelte';
	import Copyable from './Copyable.svelte';
	let referralLink = 'loading...';
	$: if (typeof window !== 'undefined')
		referralLink = $EthiClaims?.referral_code
			? `https://${
					$IsNative || $IsElectron
						? 'app.' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')
						: window.location.host
			  }/register?ref=${$EthiClaims?.referral_code}`
			: 'loading...';
</script>

<div
	transition:fade
	class="text-center h-[45vh] flex flex-col items-stretch mb-auto justify-around"
>
	<p class="text-xl font-bold text-center">Get one month free when you refer a friend!</p>
	<div class="flex flex-col items-center my-1" />
	<p class="mb-4">Your friend gets a free month too!</p>
	<Copyable
		textToCopy={$EthiClaims?.referral_code ? referralLink : 'loading...'}
		description={'your personal referral link'}
	/>
	<div class="text-xl -mb-1 -mt-2 font-bold {$Karma > 20 ? 'text-green-500' : 'text-yellow-200'}">
		{Math.floor($Karma / 20)}
	</div>
	<div class="mb-2">friends referred</div>
	<Sharing url={referralLink} />
</div>
